<template>
    <div class="row child-component">

        <div class="col-lg-7 col-md-8 col-sm-8 col-xs-6 mx-auto" style="
    background-color: #e7efeed6;
">

            <div class="d-block row col-lg-8 mx-auto well">
                <div class="fieldborder text-right" style="margin-top: 15px;">
                    <button type="button" class="btn btn-primary btn-md" style="margin-left:60px;"
                        @click="downloadUploadTemplate">Download Template </button>
                </div>
                <div class="fieldborder" style="margin-top: -4px">
                    <label for="axlocatoin" class="lbltxt">ATTACH THE OMNICOM EXTERNAL VENDOR FORM COMPLETED BY THE VENDOR</label>

                    <vue-dropzone :duplicateCheck="true" ref="myVueDropzone" id="dropzone" :useCustomSlot="true"
                        class="customdropzone" :include-styling="false" :options="dropzoneOptions"
                        v-on:vdropzone-file-added="UploadedToDropzone" v-on:vdropzone-removed-file="RemovedFromDropzone">
                        <div class="dropzone-custom-content">
                            <p class="Drag-and-drop-file-h">
                                <b-img style="
                        margin-top: -7px !important;
                        font-family: 'Open Sans';
                      " />
                                Drag and drop template here or
                                <span class="draganddropspan"> browse </span>

                            </p>
                        </div>
                    </vue-dropzone>
                </div>
                <div class="note-section mt-n2">
                    <label>Note <span style="color:red">*</span></label>
                    <p>If you have not received the Completed Omnicom External Vendor Form, you will not be able to continue. Please click the Download Template button to retrieve form, have the vendor complete the form, and then you may start this vendor request.</p>
                </div>
                <br>
                <br>
                <br>
                <br>
            </div>
        </div>

        <div class="form-group pt-60 text-right  footer-buttons" style="padding-right:96px !important;">
            <button class="btn btn-link text-uppercase" @click="Cancel()">
                CANCEL
            </button>
            <button class="text-uppercase btn custom-button btn-primary" @click="Previous()">
                BACK
            </button>
            <button v-if="$v.$invalid" class="text-uppercase btn custom-button btn-primary" :disabled="$v.$invalid">
                NEXT
            </button>
            <button v-else class="text-uppercase btn custom-button btn-primary" :disabled="$v.$invalid" @click="Next()">
                NEXT
            </button>

        </div>
    </div>
</template>

<style scoped>
.customdropzone {
    width: auto !important;
    height: auto !important;
    border-radius: 8px !important;
    border: dashed 2px #e9eef0 !important;
    background-color: #ffffff !important;
    cursor: pointer !important;
}

.Drag-and-drop-file-h {
    margin-left: 18px !important;
    margin-top: 14px !important;
    width: 387px !important;
    height: 10px !important;
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 0.71 !important;
    letter-spacing: normal !important;
    /* color: #a2a9ad !important; */
}

.zipcodevalidationmsg {
    /* margin-top: -20px !important; */
    width: 157px !important;
    font-size: 10px !important;
    color: #df5678 !important;
    font-family: "Open Sans" !important;
    font-size: 11px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: italic !important;
    line-height: 0.8 !important;
    letter-spacing: normal !important;
    text-align: left !important;
}

.error-message1 {
    height: 14px !important;
    font-size: 10px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #df5678 !important;
}

.draganddropspan {
    color: #00a1d2;
}

.error-message {
    padding-top: 10px;
    padding-bottom: 5px;
    height: 14px !important;
    font-size: 12px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #df5678 !important;
}

.floating-label-input {
    margin-top: 0px !important;
}

.fieldborder {
    padding-bottom: 30px !important;
}

.omniipclass {
    /* width: 415px !important; */
    height: 35px !important;
    border: #e9eef0 !important;
    /* style="width:415px; height:35px;" */
}

.Text {
    width: 250px;
    height: 196px;
    font-family: "Open Sans";
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.Text .text-style-1 {
    font-weight: 600;
}
</style>

<script>
import {
    mapGetters
} from "vuex";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import {
    required,
    minLength
} from "vuelidate/lib/validators";
export default {
    props: {
        id: {
            type: Number,
            required: false,
        },
        isDraft: {
            type: Boolean,
            required: false
        }
    },
    activated() {
        this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
    },
    data() {
        return {
            deleteIcon: require("@/assets/Icons/delete.svg"),
            closeFilterIcon: require("../../../assets/Icons/file.png"),
            dropzoneOptions: {
                paramName: "file",
                //params: {'param_1':'xyz','para_2': localid },
                previewTemplate: this.template(),
                url: `${process.env.VUE_APP_API_ROOT}api/v1/mdm/Upload`,
                uploadMultiple: true,
                maxFiles: 5,
                minFiles: 1,
                maxFilesize: 10,
                addRemoveLinks: false,
                parallelUploads: 5,
                createImageThumbnails: false,
                autoProcessQueue: false,
                //  headers:{"Authorization":'Bearer eyJraWQiOiJRS2V5YUxEY2RuQm9YKzlibm4rTGJQbjQrMVhiMjFuR2hHQ0l2aHpPQmJrPSIsImFsZyI6IlJTMjU2In0.eyJhdF9oYXNoIjoicldwdUNRbV9PenBvZjhwUG1iOFg4QSIsInN1YiI6IjgzNWU1NGQxLWY4YzMtNDJiYy1hZGQ2LTI4Y2RmZjdiNTc4ZiIsImNvZ25pdG86Z3JvdXBzIjpbImV1LXdlc3QtMV9yemluaUtwNzJfT01HLUFERlMiXSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LXdlc3QtMS5hbWF6b25hd3MuY29tXC9ldS13ZXN0LTFfcnppbmlLcDcyIiwiY29nbml0bzp1c2VybmFtZSI6Ik9NRy1BREZTX2NoYW5kcnUucG9ubnVzYW15QGFubmFsZWN0LmNvbSIsImdpdmVuX25hbWUiOiJDaGFuZHJ1IiwiYXVkIjoiNzM3MGlzOXZnb2Ewb2g2OXR2MHY2dDAxdXUiLCJpZGVudGl0aWVzIjpbeyJ1c2VySWQiOiJjaGFuZHJ1LnBvbm51c2FteUBhbm5hbGVjdC5jb20iLCJwcm92aWRlck5hbWUiOiJPTUctQURGUyIsInByb3ZpZGVyVHlwZSI6IlNBTUwiLCJpc3N1ZXIiOiJodHRwOlwvXC9vbWdzc28ub25lb21nLmNvbVwvYWRmc1wvc2VydmljZXNcL3RydXN0IiwicHJpbWFyeSI6InRydWUiLCJkYXRlQ3JlYXRlZCI6IjE2MDE5OTU4MzIxMTEifV0sInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjA3MzM4MjM0LCJleHAiOjE2MDkzMDg5MzUsImlhdCI6MTYwOTMwNTMzNSwiZmFtaWx5X25hbWUiOiJQb25udXNhbXkifQ.A66H0Vuv-pxNRKKOvG6BXsu4bK-Q0NfI5ORK8A8F8Vc59-RwHnuGc4tOL9TZT215YGA8kAh3o4WVs0YMFwXX5hmt7jKOCKKQyCjDhoLMPKNDVZ5eWULEoXVqHky5ZewvVc5y3Evo4rLsfHnyT1OC4j24oc8lwFFOshnqGGzkVNQ8zE4z39MdlioC9zQVxSzaxbAbnyol5qHQWQMXFhLHZOlrQ_5qxYirGdeZd0W7gdY6W8Asqj6thQ37tLf9537B6H54UKPxVeQYzxazbgsWqOFYs8jcMVTxI8dEIK25V-LgPf5Lba3BhqqaVGU_kVcPHH0qzmtRr38tfMIsZi-2Bg'},
                // headers: { "My-Awesome-Header": "header value", GUID: localStorage.getItem("UploadfileId") },
                init: function () {
                    this.on("addedfile", function (file) {
                        if (this.files.length > 5) {
                            this.removeFile(file);
                            window.alert("Maximum File Upload limit is 5!");
                        }
                    });

                    this.on("removedfile", function () { });
                },
            },
        }
    },
    validations: {
        MediaVendor: {
            CompletedForms: {
                minLength: minLength(1),
                required
            }
        }
    },
    components: {
        vueDropzone: vue2Dropzone
    },
    computed: {
        IsEdit() {
            return this.id && !isNaN(this.id) && parseInt(this.id) > 0 ? true : false;
        },
        ...mapGetters('mediavendor', {
            MediaVendor: 'MediaVendor',
        })
    },
    watch: {
        "MediaVendor.IsEditDataLoaded": function (val) {
            if (this.IsEdit == true && val == true) {
                this.ManuallyUploadFiles(this.MediaVendor.CompletedForms);
                this.$set(this.MediaVendor, "CompletedForms", this.GetFilesObject());
            }
        },
    },
    mounted() {
        if (this.IsEdit == true && this.MediaVendor.IsEditDataLoaded == true) {
            this.ManuallyUploadFiles(this.MediaVendor.CompletedForms);
            this.$set(this.MediaVendor, "CompletedForms", this.GetFilesObject());
        }
    },
    methods: {
        ManuallyUploadFiles(files) {
            if (files != null && files.length > 0) {
                files.forEach((fileUploaded) => {
                    let file = {
                        size: fileUploaded.Length,
                        name: fileUploaded.Name,
                        type: fileUploaded.Extension,
                    };
                    var url = fileUploaded.FullName;
                    this.$refs.myVueDropzone.manuallyAddFile(file, url);
                });
            }
        },
        UploadedToDropzone() {
            this.$set(this.MediaVendor, "CompletedForms", this.GetFilesObject());
        },
        RemovedFromDropzone() {
            this.$set(this.MediaVendor, "CompletedForms", this.GetFilesObject());
        },
        GetFilesObject() {
            let files = [];
            if (this.$refs.myVueDropzone.dropzone.files && this.$refs.myVueDropzone.dropzone.files.length > 0) {
                let filesExists = this.$refs.myVueDropzone.dropzone.files;
                for (let i = 0; i < filesExists.length; i++) {
                    files.push({
                        name: filesExists[i].name,
                        file: filesExists[i]
                    });
                }
                return files;
            } else {
                return files;
            }
        },
        async downloadUploadTemplate() {
            let file = {
                Name: "External Form - Media Vendor.pdf"
            };
            this.$store
                .dispatch("mediavendor/DownloadUploadTemplate", file)
                .then((response) => {
                    const blob = new Blob([response.data], {
                        type: file.Extension
                    });
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = file.Name;
                    link.click();
                    URL.revokeObjectURL(link.href);
                })
                .catch((ex) => {
                    if (ex.response) {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Error while downloading file. ${ex.response.data}`
                        );
                    } else {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Error while downloading file. ${ex}`
                        );
                    }
                });
        },
        Next() {
            this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
            if (this.id) {
                this.$router.push({
                name: "Media Vendor Credit Info",
                params: {
                    id: this.id,
                    isDraft: this.isDraft,
                },
                });
            } else {
                this.$router.push({
                name: "Media Vendor Credit Info",
                });
            }
        },
        Cancel() {
            this.$router.push("/VendorRequests");
        },
        Previous() {
            if (this.id) {
                this.$router.push({
                    name: "Media Vendor Ledger Media",
                    params: {
                        id: this.id,
                        isDraft: this.isDraft
                    }
                });
            } else {
                this.$router.push({
                    name: "Media Vendor Ledger Media"
                });
            }
        },
        template: function () {
            return `<div class="dz-preview dz-file-preview" style='background-color:#ffffff !important;display:flex;'>

                <div class="dz-details" style='margin-right:79px !important;'>
                    <div class="dz-size" style="display:none;" ><span data-dz-size></span></div>
                    <div class="FileforNewClientxls-Copy" style="margin-left:20px;width:280px;cursor:default !important;" ><span style='word-break:break-all !important;font-size:14px !important;font-weight:400;font-stretch:normal;font-style: normal;line-height: 0.71;color: #00a1d2 !important; font-family:"Open Sans" !important;' data-dz-name></span></div>
                </div>
                <a>
                 <img data-dz-remove=""  style='' src='/icons/fileremove.svg'  @click="DeleteServerFile()" ></img>
                </a>
            </div>`;
        },

    }
}
</script>
